let baseURL;
switch(process.env.NODE_ENV) {
    case 'development':
        baseURL = "http://192.168.1.138:8090/"
        break;
    case 'production':
        // baseURL = "http://barry.local.com:8090/"
        baseURL = "https://qd.gzzdwx.com/"
        break;
    default:
        baseURL = "https://qd.gzzdwx.com/"
        break;
}

export default {
    baseURL
}