import Vue from 'vue'
import router from './router'
import axios from 'axios'

import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import App from './App.vue'
import store from './store'
import Element from 'element-ui'
import './assets/iconfont/iconfont.css'
import env from './env'

//导入element组件
import "./plugins/element.js";
//导入element-ui样式表
import "element-ui/lib/theme-chalk/index.css";

import MetaInfo from 'vue-meta-info'

Vue.use(MetaInfo)

axios.defaults.baseURL = env.baseURL;
axios.defaults.timeout = 8000;

// axios.defaults.baseURL = env.baseURL;
// // 接口错误拦截
// axios.interceptors.response.use(function(response){
//   let res = response.data;
//   // 判断用户是否登录
//   if(res.status == 0){
//     return res.data;
//   }else if(res.status == 10){
//     window.location.href = '/#/login';
//   } else {
//     alert(res.msg);
//   }
// });

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

const MyAxios = axios.create({
  transformRequest: [function (data) {
    // 将数据转换为表单数据
    let ret = ''
    for (let it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    return ret
  }],
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})


// 添加请求拦截器，在请求头中加token
MyAxios.interceptors.request.use(
  config => {
    if (localStorage.getItem('token')) {
      config.headers.common['token'] = localStorage.getItem('token');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
});

Vue.use(VueAxios,MyAxios);
Vue.use(VueCookie);
Vue.use(Element);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

router.beforeEach((to, from, next) => {
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
  const token = localStorage.getItem('token') // 获取账号的token鉴权
  if (to.meta.requireAuth) { // 判断需要进入到哪个入口
    if (token) { // 判断账号是否登录
      next()
    } else {
      router.push({ name: 'login' })
    }
  } else {
    next()
  }
})