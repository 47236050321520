import Vue from 'vue'
import Router from 'vue-router'

import Home from './pages/home'

const Index = () => import("@/pages/index")
const ClassList = () => import("@/pages/classlist")
const ClassDetail = () => import("@/pages/classdetail")
const QuestionBank = () => import("@/pages/questionbank")
const QuestionBankDetail = () => import("@/pages/questionBankDetail")
const UserInfo = () => import("@/pages/userinfo")

const News = () => import("@/pages/news")
const NewsDetail = () => import("@/pages/newsdetail")

const Cart = () => import("@/pages/cart")
const orderConfirm = () => import("@/pages/orderConfirm")
const orderPay = () => import("@/pages/orderPay")
const orderSuccess = () => import("@/pages/orderSuccess")
const orderDetail = () => import("@/pages/orderDetail")

// const Search = () => import("@/pages/search")
const Video = () => import("@/pages/video")
const Paper = () => import("@/pages/paper")
const Practice = () => import("@/pages/practice")
const Showquestion = () => import("@/pages/showquestion")
const Submitresults = () => import("@/pages/submitresults")

const Live = () => import("@/pages/live")
const LiveDetail = () => import("@/pages/livedetail")
const LivePlay = () => import("@/pages/liveplay")

const Login = () => import("@/pages/login")
const Register = () => import("@/pages/register")
const Forget = () => import("@/pages/forget")

const Notfound = () => import("@/pages/notfound")
Vue.use(Router);

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

 const router = new Router({
    //  mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            redirect: '/index',
            children:[
                {
                    path: 'index',
                    name: 'index',
                    component: Index,
                    meta: {title: '首页'}
                },
                {
                    path: 'classlist',
                    name: 'classlist',
                    component: ClassList,
                    meta: {title: '课程列表'}
                },
                {
                    path: 'classdetail/:id',
                    name: 'classdetail',
                    component: ClassDetail,
                    meta: {
                        title: '课程详情',
                        requireAuth: true
                    }
                },
                {
                    path: 'questionbank',
                    name: 'questionbank',
                    component: QuestionBank,
                    meta: {title: '题库中心'}
                },
                {
                    path: 'questionBankDetail/:id',
                    name: 'questionBankDetail',
                    component: QuestionBankDetail,
                    meta: {
                        title: '题库中心',
                        requireAuth: true
                    }
                },
                {
                    path: 'news',
                    name: 'news',
                    component: News,
                    meta: {title: '资讯中心'}
                },
                {
                    path: 'live',
                    name: 'live',
                    component: Live,
                    meta: {title: '直播中心'}
                },
                {
                    path: 'login',
                    name: 'login',
                    component: Login,
                    meta: {title: '用户登录'}
                },
                {
                    path: 'register',
                    name: 'register',
                    component: Register,
                    meta: {title: '用户注册'}
                },
                {
                    path: 'forget',
                    name: 'forget',
                    component: Forget,
                    meta: {title: '忘记密码'}
                },
                {
                    path: 'userinfo/:name',
                    name: 'userinfo',
                    component: UserInfo,
                    meta: {
                        title: '用户中心',
                        requireAuth: true
                    }
                },
                {
                    path: 'cart',
                    name: 'cart',
                    component: Cart,
                    meta: {title: '购物车',requireAuth: true}
                },
                {
                    path: 'orderConfirm',
                    name: 'orderConfirm',
                    component: orderConfirm
                },
                {
                    path: 'orderPay',
                    name: 'orderPay',
                    component: orderPay
                },
                {
                    path: 'orderDetail',
                    name: 'orderDetail',
                    component: orderDetail
                },
                {
                    path: 'orderSuccess',
                    name: 'orderSuccess',
                    component: orderSuccess
                },
                // {
                //     path: '/search',
                //     name: 'search',
                //     component: Search
                // }
            ]
        },
        {
            path: '/video',
            name: 'video',
            component: Video,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/paper/:obId/:type/:courseContentId',
            name: 'paper',
            component: Paper,
            meta: {
                requireAuth: true,
                title: '试题'
            }
        },
        {
            path: '/practice/:obId/:type/:courseContentId',
            name: 'practice',
            component: Practice,
            meta: {
                requireAuth: true,
                title: '练习题题'
            }
        },
        {
            path: '/submitresults/:resultId',
            name: 'submitresults',
            component: Submitresults,
            meta: {
                requireAuth: true,
                title: '提交结果'
            }
        },
        {
            path: '/showquestion/:resultId',
            name: 'showquestion',
            component: Showquestion,
            meta: {
                requireAuth: true,
                title: '查看结果'
            }
        },
        {
            path: '/newsdetail/:id/:categoryId',
            name: 'newsdetail',
            component: NewsDetail,
            meta: {
                requireAuth: false,
                title: '资讯详情'
            }
        },
        {
            path: '/livedetail',
            name: 'livedetail',
            component: LiveDetail,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/liveplay',
            name: 'liveplay',
            component: LivePlay,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '*',
            name: 'notfound',
            component: Notfound
        }
    ]
});

export default router;