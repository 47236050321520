<template>
  <div class="nav-banner">
      <!-- <div class="top-banner" v-show="bannerShow">
          <div class="banner-close" @click="bannerClose"></div>
      </div> -->
      <div class="tablist" ref="header" :class="headerFixed?'issFixed':''">
        <div class="tablist-wapper">
            <div class="banner-logo">
              <a href="/">
                <img src="../assets/img/logo.png" alt="">
              </a>
            </div>
            <div class="topbar-menu">
              <!--导航栏-->
              <el-menu active-text-color="none" text-color="none" mode="horizontal" class="el-menu-demo">
                <el-menu-item :index="'/Index'"  @click="toPage('/Index')">首页</el-menu-item>
                <el-menu-item :index="'/ClassList'" @click="toPage('/ClassList')">课程中心</el-menu-item>
                <el-menu-item :index="'/Live'" @click="toPage('/Live')">直播中心</el-menu-item>
                <el-menu-item :index="'/QuestionBank'" @click="toPage('/QuestionBank')">题库</el-menu-item>
                <el-menu-item :index="'/News'" @click="toPage('/News')">资讯</el-menu-item>
              </el-menu>
            </div>
            <div class="topbar-lesson">
                <a class="my-class" @click="jumpMyClass(username)"><i class="icon-lesson"></i>我的课程</a>
                <span>|</span>
                <router-link to="/Cart" class="my-cart"><i class="icon-cart"></i>购物车<span class="cartCount" v-if="username">{{cartCount}}</span></router-link>
              </div>
            <div class="topbar-user">
              <div>
                <a class="userInfo" v-if="user" @click="jumpMyClass(username)">{{user}}</a>
                <a href="javascript:;" class="login" v-if="!user" @click="logIn">登录</a>
                <span>/</span>
                <a href="javascript:;" v-if="user" @click="logOut()" class="logOut">退出</a>
                <router-link to="/Register" class="register" v-if="!user">注册</router-link>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'nav-header',
    inject:['reload'],
    data(){
      return {
        activeIndex: "/Index",
        bannerShow: true,
        count: false,
        offsetTop: 0,
        offsetHeight: 0,
        headerFixed: 0,
        user: localStorage.getItem('user')
      }
    },
    computed:{
      username() {
        // console.log(this.$store.state.user)
        return this.$store.state.user;
      },
      cartCount() {
        // console.log(this.$store.state.cartCount)
        return this.$store.state.cartCount;
      }
    },
    onLoad(){
      // this.bannerShow = true;
    },
    created() {
      this.$nextTick(() => {
        // 获取吸顶元素的dom
        let header = this.$refs.header;
        // 吸顶元素到top的距离
        this.offsetTop = header.offsetTop;
        // 元素自身的高度
        this.offsetHeight = header.offsetHeight;
        // 监听滚动条
        window.addEventListener("scroll", this.handleScroll);
      });
    },
    mounted() {
      this.getCount();
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      // bannerClose(){
      //   this.bannerShow = false
      // },
      handleScroll() {
        // 得到页面滚动的距离，兼容写法
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        // 判断页面滚动的距离是否大于吸顶元素的位置
        this.headerFixed = scrollTop > this.offsetTop - this.offsetHeight;
      },
      //跳转页面
      toPage(path) {
        this.$router.push(path);
      },
      // 登录
      logIn() {
        this.$router.push('/login');
      },
      jumpMyClass(name) {
        this.$router.push({
           name:'userinfo',
           params: {
             "name":name
           }
        })
      },
      // 获取购物车小圆点
      getCount() {
        let token = localStorage.getItem('token');
        if(token != undefined) {
          this.axios({
              method: 'POST',
              url: 'api/v2/yun/cart/count.html'
          }).then((res) => {
              // res.data.result.count
              if(res.data.code == 1000) {
                this.count = false;
              } else if(res.data.code == 200){
                let cartCount = res.data.result.count
                this.$store.commit('saveCartCount',cartCount);
                this.count = true;
              }
          })
        }
      },
      // 登出
      logOut() {
        const id = localStorage.getItem('userId');
        console.log(id)
        this.axios({
            method: 'POST',
            url: 'api/v2/yun/login/logout.html',
            // data: {
            //   id: id
            // }
        }).then(() => {
            this.reload();
            sessionStorage.removeItem('key');
            localStorage.removeItem('key')
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push('/login');
            this.$store.commit("$_removeStorage")
            this.$store.commit('saveCartCount','0');
            // window.location.reload()
        })
      }
      
    }
}
</script>

<style lang="scss" scoped>
  .nav-banner {
    width: 100%;
    min-width: 1200px;
    .top-banner {
      position: relative;
      width: 100%;
      min-width: 1200px;
      height: 80px;
      background: red;
      .banner-close {
        position: absolute;
        top: 9px;
        right: 19px;
        width: 24px;
        height: 24px;
        background: green;
      }
    }
    .tablist {
      width: 100%;
      min-width: 1200px;
      height: 70px;
      background: #FFFFFF;
      align-content: center;
      .tablist-wapper {
        margin: 0 auto;
        width: 1200px;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .banner-logo {
          margin-top: 15px;
          width: 140px;
          height: 34px;
        }
        .topbar-menu {
          width: 562px;
          ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: none;
            li {
              position: relative;
              height: 70px;
              line-height: 70px;
              a {
                font-size: 16px;
                color: #333333;
              }
            }
            .active {
              font-size: 20px;
              font-weight: bold;
              color: #FA4B66;
              &:after {
                content: '';
                position: absolute;
                bottom: 3px;
                left: 0;
                width: 100%;              
                height: 2px;
                background: #FF5A74;
              }
            }
          }
        }
        .topbar-lesson {
          display: flex;
          line-height: 70px;
          .my-class {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            &:hover {
              color: #FF5A74;
              cursor: pointer;
            }
            .icon-lesson {
              display: inline-block;
              vertical-align: text-top;
              margin-right: 6px;
              width: 20px;
              height: 18px;
              background: url("../assets/img/myclass.png") no-repeat;
            }
          }
          span {
            font-size: 16px;
            color: #DEDEDE;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: -2px;
          }
          .my-cart {
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #333333;
            position: relative;
            // &:hover {
            //   color: #FF5A74;
            // }
            .icon-cart {
              display: inline-block;
              vertical-align: text-top;
              margin-right: 6px;
              width: 20px;
              height: 18px;
              background: url("../assets/img/cart.png") no-repeat;
            }
            .cartCount {
              position: absolute;
              top: 27px;
              right: -35px;
              width: 20px;
              line-height: 19px;
              text-align: center;
              font-size: 12px;
              color: #fff;
              height: 20px;
              border-radius: 23px;
              background-color: #FF5A74;
            }
          }
        }
        .topbar-user {
          line-height: 70px;
          display: flex;
          .userInfo {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
          }
          span {
            margin-left: 4px;
            margin-right: 4px;
            font-size: 16px;
            color: #333333;
          }
          .login {
            font-size: 14px;
            color: #FF5A74;
          }
          .register {
             font-size: 14px;
             color: #333333;
          }
          .logOut {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    .issFixed {
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      z-index: 99;
    }
  }
</style>
