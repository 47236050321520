import Vue from 'vue'
import Vuex from 'vuex'
 
Vue.use(Vuex)
const key = 'user'
const store = new Vuex.Store({
 state () {
  return {
   user: null, // 
   cartCount: 0//购物车商品数量
  }
 },
 getters: {
  getStorage: function (state) {
   if (!state.user) {
    state.user = JSON.parse(localStorage.getItem(key))
   }
   return state.user
  }
 },
 mutations: {
  $_setStorage (state, value) {
   state.user = value
   localStorage.setItem(key, value)
  },
  $_removeStorage (state) {
   state.user = null
   localStorage.removeItem(key)
  },
  saveCartCount(state, count) {
    state.cartCount = count;
  }
 },
//  actions: {
//     saveCartCount(context, count) {
//         context.commit('saveCartCount', count);
//     }
//  }
})

export default store