<template>
    <div class="nav-footer">
        <div class="footer-wrap">
            <div class="footer-left">
                <p>网站反馈：admin@gzzdwx.com</p>
                <p>Copyright 2019 粤ICP含19015523号 版权所有 网校云课堂</p>
            </div>
            <div class="footer-right">
                <div class="footer-right-wrap">
                    <div class="right-word">
                        <p>咨询热线：010-32029685</p>
                        <p>联系邮箱：edu@gzzdwx.com</p>
                    </div>
                    <div class="pic" style="display: none;">

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'nav-footer'
}
</script>

<style lang="scss">
    .nav-footer {
        width: 100%;
        min-width: 1200px;
        background: url("../assets/img/footerImg.jpg") no-repeat;
        height: 140px;
        padding-top: 25px;
        box-sizing: border-box;
        .footer-wrap {
            margin: 0 auto;
            width: 1200px;
            display: flex;
            .footer-left {
                margin-top: 20px;
                margin-right: 129px;
                p {
                    color: #ffffff;
                    font-family: Source Han Sans CN;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
            .footer-right {
                .footer-right-wrap {
                    display: flex;
                    .right-word {
                        margin-top: 20px;
                        margin-right: 30px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        color: #EEEEEE;
                        p {
                            margin-bottom: 20px;
                        }
                    }
                    .pic {
                        width: 90px;
                        height: 90px;
                        background: pink;
                    }
                }
            }
        }
    }
</style>